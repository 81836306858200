import Sorting from "./sorting";

export default class Configurator {
    private sorting: Sorting;
    private bulkData = {
        elements: [],
        calculated: [],
        errors: []
    };
    private bulkInfo =  "";
    private saveButton: HTMLButtonElement;

    constructor() {
        this.saveButton = document.querySelector("#erp_warehouse_location_bulk_save") as HTMLButtonElement;
        this.sorting = new Sorting(() => {
            this.onBulkMoveStart()
        }, () => {
            this.onBulkMoveEnd()
        });
    }
    onOpenConfigratorDialog() {
        this.onRemoveAllElements();
        this.sorting.setup();
        setTimeout(() => {
            this.onBulkAddWarehouseLocations()
            setTimeout(() => {
                this.onBulkWarehouseLocationsResetConfig()
                setTimeout(() => {
                    this.sorting.clone()
                }, 100);
            }, 100);
        }, 100);
    }

    onBulkWarehouseLocationsResetConfig() {
        (document.querySelector("#kind2_bulk_add_original_items") as HTMLElement).classList.remove("ui-sortable");
        (document.querySelector("#kind2_bulk_add_cloned_items") as HTMLElement).innerHTML = "";
        document.querySelectorAll("#kind2_bulk_add_configurator li.exclude-me").forEach((e) => {
            e.remove()
        });
        (document.querySelectorAll("#kind2_bulk_add_configurator li") as NodeListOf<HTMLElement>).forEach((li) => {
            li.style.visibility = "";
            li.style.position = "";
            // @ts-ignore
            li.style['z-index'] = "";
            li.style.left = "ß";
            li.style.top = "0";
            li.classList.remove('ui-sortable-handle')
        })
    }

    onBulkRemoveWarehouseLocations() {

    }

    onBulkAddWarehouseLocations() {
        const li = document.createElement("li")
        li.innerHTML = this.generateBulkAddElement();
        li.classList.add("col-3");
        li.style.marginBottom = "5px";
        (document.querySelector("#kind2_bulk_add_original_items") as HTMLElement).appendChild(li)
        this.onBulkWarehouseLocationsResetConfig()
        setTimeout(() => {
            this.sorting.clone()
        }, 10)

    }


    makeid(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    generateBulkAddElement() {
        const r = this.makeid(7);
        let html = '';
        html += '<div class="bulk_element card" data-id="'+r+'" style="margin:3px;">'
        html += '<div class="card-body" style="padding: 10px;">'
        html += '<div class="row d-flex mb-2">'
        html += '<div class="col-9">'
        html += '<span class="bulk_selector_replacement">Zahlen</span>'
        html += '<select class="bulk_selector form-select form-select-sm"><option value="n">Zahlen</option><option value="l">Buchstaben</option><option value="c">Zeichen</option></select>'
        html += '</div>'
        html += '<div class="col-2">'
        html += '<button class="btn btn-sm btn-secondary bulk_remove">X</button>'
        html += '</div>'
        html += '</div>'
        html += '<div class="bulk_input_n bulk_add_parent row"><div class="col-6"><input class="bulk_add form-control form-control-sm" type="number" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" min="0" /></div><div class="col-6"><input class="bulk_add form-control form-control-sm" type="number" onkeypress="return event.keyCode === 8 || event.charCode >= 48 && event.charCode <= 57" min="0" /></div></div>'
        html += '<div class="bulk_input_l bulk_add_parent row hidden"><div class="col-6"><input class="bulk_add form-control form-control-sm" type="text" maxlength="1" /></div><div class="col-6"><input class="bulk_add form-control form-control-sm" type="text" maxlength="1" /></div></div>'
        html += '<div class="bulk_input_c bulk_add_parent row hidden"><div class="col-12"><input class="bulk_add bulk_add_full form-control form-control-sm" type="text" /></div></div>'
        html += '</div>'
        return html;
    }

    onRemoveAllElements() {
        Array.from(document.querySelectorAll(`#kind2_bulk_add_original_items .bulk_element`)).forEach((elem) => {
            if (elem) {
                const li = elem.closest("li")
                if (li) {
                    // @ts-ignore
                    elem.closest("li").remove();
                }
            }
        });
        this.onBulkWarehouseLocationsResetConfig()
        setTimeout(() => {
            this.sorting.clone()
            this.onBulkChangeInput()
        }, 10)
    }

    onRemoveBulkElement(event: any) {
        const target = event.target
        const id = target.closest(".bulk_element").getAttribute("data-id");
        const elem = document.querySelector(`#kind2_bulk_add_original_items .bulk_element[data-id='${id}']`)
        if (elem) {
            const li = elem.closest("li")
            if (li) {
                // @ts-ignore
                elem.closest("li").remove();
            }
        }
        this.onBulkWarehouseLocationsResetConfig()
        setTimeout(() => {
            this.sorting.clone()
            this.onBulkChangeInput()
        }, 10)
    }

    onBulkChangeSelector(event: any) {
        const target = event.target
        const val = target.value
        const parent = target.closest('.bulk_element')
        parent.querySelector('.bulk_selector_replacement').innerHTML = parent.querySelector(".bulk_selector option:checked").text
        parent.querySelectorAll('.bulk_add_parent').forEach((p: any) => {p.classList.add('hidden')})
        if (val === 'n') {
            parent.querySelector('.bulk_input_n').classList.remove('hidden');
        } else if (val === 'l') {
            parent.querySelector('.bulk_input_l').classList.remove('hidden');
        } else if (val === 'c') {
            parent.querySelector('.bulk_input_c').classList.remove('hidden');
        }
        this.onBulkWarehouseLocationsResetConfig()
        setTimeout(() => {
            this.sorting.clone()
            this.onBulkChangeInput()
        }, 10)
    }

    onBulkMoveStart() {
        (document.querySelector('#kind2_bulk_add_original_items') as HTMLElement).classList.add("moving");
        (document.querySelector('#kind2_bulk_add_cloned_items') as HTMLElement).classList.remove("d-none");
        (document.querySelectorAll('.bulk_selector') as NodeListOf<HTMLElement>).forEach(p => p.style.display = 'none');
        (document.querySelectorAll('.bulk_selector_replacement') as NodeListOf<HTMLElement>).forEach(p => p.style.display = 'block')
    }

    onBulkMoveEnd() {
        (document.querySelector('#kind2_bulk_add_original_items') as HTMLElement).classList.remove("moving");
        (document.querySelector('#kind2_bulk_add_cloned_items') as HTMLElement).classList.add("d-none");
        (document.querySelectorAll('.bulk_selector') as NodeListOf<HTMLElement>).forEach(p => p.style.display = 'block');
        (document.querySelectorAll('.bulk_selector_replacement') as NodeListOf<HTMLElement>).forEach(p => p.style.display = 'none')
    }

    onBulkChangeInput() {
        const wrapper = document.querySelector("#kind2_bulk_add_original_items") as HTMLElement
        const elements = wrapper.querySelectorAll("li")
        this.bulkData.elements = [];
        elements.forEach((e, i) => {
            // @ts-ignore
            const values = Array.from(e.querySelectorAll(".bulk_add_parent:not(.hidden) input")).map(v => v.value)
            const select = (e.querySelector('.bulk_selector option:checked') as HTMLInputElement).value;
            // @ts-ignore
            this.bulkData.elements[i] = {
                type: select,
                values: values
            }
        })
        this.bulkCalculateLocations();
    }

    bulkCalculateLocations() {
        const errors: { message: string; index: any; }[] = []
        let newCalculated: any[] = []
        this.bulkData.calculated = []
        this.bulkData.errors = []
        this.bulkData.elements.forEach((data: { type: any; values: any; }, i: any) => {
            const generated: ConcatArray<any> = []
            const type = data.type;
            const values = data.values;
            if (type === "n") {
                if (values.length < 1) {
                    errors.push({
                        message: "Leer",
                        index: i
                    })
                } else {
                    let start = values[0];
                    let end = values[1];
                    if (start.length < 1 || end.length < 1) {
                        errors.push({
                            message: "Leer",
                            index: i
                        })
                    } else {
                        start = parseInt(start);
                        end = parseInt(end);
                        const range = end - start;
                        if (range <= 0) {
                            errors.push({
                                message: "Start muss hinter Ende liegen.",
                                index: i
                            })
                        } else {
                            const el: string[] = []
                            for (let i = 0; i <= range; i++) {
                                const newL = "" + (start + i);
                                el.push(newL)
                            }
                            if (newCalculated.length > 0) {
                                const a: any[] = []
                                newCalculated.forEach((existing, i) => {
                                    el.forEach((e) => {
                                        a.push(existing + e)
                                    })
                                })
                                newCalculated = a
                            } else {
                                el.forEach((e) => {
                                    newCalculated.push(e)
                                })
                            }
                        }
                    }
                }
            } else if (type === "l") {
                if (values.length !== 2) {
                    errors.push({
                        message: "Leer",
                        index: i
                    })
                } else {
                    const start = values[0];
                    const end = values[1];
                    if (start.length !== 1 || end.length !== 1) {
                        errors.push({
                            message: "Leer",
                            index: i
                        })
                    } else {
                        const range = this.getSpace(start, end);
                        if (range <= 0) {
                            errors.push({
                                message: "Start muss hinter Ende liegen.",
                                index: i
                            })
                        } else {
                            const el: string[] = []
                            for (let i = 0; i <= range; i++) {
                                const l = this.getIndexOfLetter(start);
                                const newL = this.getLetterByIndex(l + i).toUpperCase();
                                el.push(newL)
                            }
                            if (newCalculated.length > 0) {
                                const a: any[] = []
                                newCalculated.forEach((existing, i) => {
                                    el.forEach((e) => {
                                        a.push(existing + e)
                                    })
                                })
                                newCalculated = a
                            } else {
                                el.forEach((e) => {
                                    newCalculated.push(e)
                                })
                            }
                        }
                    }
                }
            } else {
                const newL = values[0];
                if (newL.length >= 1) {
                    if (newCalculated.length > 0) {
                        newCalculated.forEach((existing, i) => {
                            newCalculated[i] = existing + newL
                        })
                    } else {
                        newCalculated.push(newL)
                    }
                }
            }
            newCalculated = newCalculated.concat(generated).filter(n => n);
        })

        // @ts-ignore
        this.bulkData.calculated = newCalculated;
        // @ts-ignore
        this.bulkData.errors = errors
        if (this.bulkData.errors.length > 0) {
            this.bulkInfo = this.bulkData.errors.map((e: { message: any, index: number }) => `Feld ${e.index+1}: ${e.message}`).join(", ");
            (document.querySelector("#erp_warehouse_locations_bulk_messages") as HTMLElement).innerHTML = `<div class="alert alert-warning" role="alert">${this.bulkInfo}</div>`
            this.saveButton.setAttribute("disabled", "disabled");
        } else {
            this.bulkInfo = `${this.bulkData.calculated.length} neue Lagerplätze werden erzeugt.`;
            (document.querySelector("#erp_warehouse_locations_bulk_messages") as HTMLElement).innerHTML = `<div class="alert alert-success" role="alert">${this.bulkInfo}</div>`
            this.saveButton.removeAttribute("disabled");
        }
    }

    getLetterByIndex(i: number) {
        const a = "abcdefghijklmnopqrstuvwxyz";
        return a.charAt(i);
    }

    getIndexOfLetter(l: string) {
        const a = "abcdefghijklmnopqrstuvwxyz";
        return a.indexOf(l.toLowerCase());
    }

    getSpace(start: any, end: any) {
        const startIndex = this.getIndexOfLetter(start);
        const endIndex = this.getIndexOfLetter(end);
        return endIndex - startIndex
    }
}